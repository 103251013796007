const initState = {
    action: null,
    error: null,
    allRequestData: {},
    allRequestValues: [],
    allRequestKeys: [],
    allCustomerData: {},
    allCustomerValues: [],
    allCustomerKeys: [],
    allOrderData: {},
    allOrderValues: [],
    allOrderKeys: [],
    allInvoiceData: {},
    allInvoiceValues: [],
    allInvoiceKeys: [],
    allPaymentData: {},
    allPaymentValues: [],
    allPaymentKeys: [],
};

export const superUserAction = {
    LOAD_ALL_REQUEST_SUCCESS: 'LOAD_ALL_REQUEST_SUCCESS',
    LOAD_ALL_REQUEST_ERROR: 'LOAD_ALL_REQUEST_ERROR',
    LOAD_ALL_ORDER_SUCCESS: 'LOAD_ALL_ORDER_SUCCESS',
    LOAD_ALL_ORDER_ERROR: 'LOAD_ALL_ORDER_ERROR',
    LOAD_ALL_CUSTOMER_SUCCESS: 'LOAD_ALL_CUSTOMER_SUCCESS',
    LOAD_ALL_INVOICE_SUCCESS: 'LOAD_ALL_INVOICE_SUCCESS',
    LOAD_ALL_PAYMENT_SUCCESS: 'LOAD_ALL_PAYMENT_SUCCESS',
};

const superUserReducer = (state = initState, action) => {
    switch(action.type){
        case superUserAction.LOAD_ALL_REQUEST_SUCCESS:
            const allRequestValues = Object.values(action.data).sort((a,b) => b.created - a.created)
            return {
                ...state,
                action: action.type,
                error: null,
                allRequestData: action.data,
                allRequestValues:allRequestValues,
                allRequestKeys: Object.keys(action.data)
            };
        case superUserAction.LOAD_ALL_ORDER_SUCCESS:
            const allOrderValues = Object.values(action.data).sort((a,b) => b.created - a.created)
            return {
                ...state,
                action: action.type,
                error: null,
                allOrderData: action.data,
                allOrderValues:allOrderValues,
                allOrderKeys: Object.keys(action.data)
            };
        case superUserAction.LOAD_ALL_CUSTOMER_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                allCustomerData: action.allCustomerData,
                allCustomerValues: action.allCustomerValues,
                allCustomerKeys: Object.keys(action.allCustomerData)
            };
        case superUserAction.LOAD_ALL_INVOICE_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                allInvoiceData: action.allInvoiceData,
                allInvoiceValues: action.allInvoiceValues,
                allInvoiceKeys: Object.keys(action.allInvoiceData)
            };
        case superUserAction.LOAD_ALL_PAYMENT_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                allPaymentData: action.allPaymentData,
                allPaymentValues: action.allPaymentValues,
                allPaymentKeys: Object.keys(action.allPaymentData)
            };
        case superUserAction.LOAD_ALL_ORDER_ERROR:
        case superUserAction.LOAD_ALL_REQUEST_ERROR:
            return {
                ...state,
                action: action.type,
                error: action.err,
            };
        default:
            return state
    }
};

export default superUserReducer;