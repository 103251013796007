const initState = {
    action: null,
    error: null,
    carModel: null,
    carModelCategory: [],
    isLoaded: false,
    carModelData: {},
    carModelValues: [],
    carModelKeys: []
};

export const carModelAction = {
    LOAD_CAR_MODEL_SUCCESS: 'LOAD_CAR_MODEL_SUCCESS',
    LOAD_CAR_MODEL_ERROR: 'LOAD_CAR_MODEL_ERROR',
    SET_CAR_MODEL_SUCCESS: 'SET_CURRENT_CAR_MODEL_SUCCESS',
    SET_CATEGORY_SUCCESS: 'SET_CATEGORY'
};

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case carModelAction.LOAD_CAR_MODEL_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                isLoaded: true,
                carModelData: action.data,
                carModelValues: Object.values(action.data),
                carModelKeys: Object.keys(action.data)
            };
        case carModelAction.SET_CAR_MODEL_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                carModel: action.carModel,
            }
        case carModelAction.SET_CATEGORY_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                carModelCategory: action.category,
            };
        case carModelAction.LOAD_CAR_MODEL_ERROR:
            return {
                ...state,
                action: action.type,
                error: action.err,
            };
        default:
            return state
    }
};

export default userReducer;