const initState = {
    authAction: null,
    authError: null,
};

export const authActions = {
    LOGIN_ERROR : 'LOGIN_ERROR',
    LOGIN_SUCCESS : 'LOGIN_SUCCESS',
    LOGOUT_SUCCESS : 'LOGOUT_SUCCESS',
};

const authReducer = (state = initState, action) => {
    switch(action.type){
        case authActions.LOGIN_SUCCESS:
        case authActions.LOGOUT_SUCCESS:
            return {
                ...state,
                authAction: action.type,
                authError: null
            };
        case authActions.LOGIN_ERROR:
            return {
                ...state,
                authAction: action.type,
                authError: action.err,
            };
        default:
            return state
    }
};

export default authReducer;