import {createTheme} from '@material-ui/core/styles';
import "@fontsource/roboto";
import "@fontsource/roboto-condensed";

export const primeMobilityTheme = createTheme({
    palette: {
        common: {
            black: '#000',
            white: '#fff'
        },
        primary: {
            light: '#CFE8E4',
            main: '#9bd0c7',
            dark: '#6b9f96',
            contrastText: '#000',
        },
        secondary: {
            light: '#E6E7E9',
            main: '#707280',
            dark: '#2F3244',
            contrastText: '#fff',
        },
        error: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#fff',
        },
        grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A100: '#d5d5d5',
            A200: '#aaaaaa',
            A400: '#303030',
            A700: '#616161',
        },
        text: {
            primary: 'rgba(35, 37, 53, 1)',
            secondary: 'rgba(51, 51, 51, 0.54)',
            disabled: 'rgba(35, 37, 53, 0.38)',
            hint: 'rgba(51, 51, 51, 0.38)',

        },
        divider: "rgba(27, 27, 27, 0.12)",
        background: {
            paper: '#FFF',
            default: '#FFF'
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'Roboto Condensed',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif'
        ].join(','),

        h1: {
            fontSize: 70,
            fontWeight: 500,
            '@media screen and (max-width: 960px)': {
                fontSize: 52,
                fontFamily: 'Roboto Condensed',
            }
        },
        h2: {
            fontSize: 44,
            fontWeight: 500,
            '@media screen and (max-width: 960px)': {
                fontSize: 40,
                fontFamily: 'Roboto Condensed',
            }
        },
        h3:{
            fontSize: 32,
            fontWeight: 500,
            '@media screen and (max-width: 960px)': {
                fontSize: 24,
                fontFamily: 'Roboto Condensed',
            }
        },
        h4:{
            fontSize: 24,
            fontWeight: 600,
            '@media screen and (max-width: 960px)': {
                fontSize: 20,
                fontFamily: 'Roboto Condensed',
            }
        },
        h5:{
            textTransform: 'uppercase',
            fontSize: 21,
            fontWeight: 600,
            color: '#9bd0c7',
            '@media screen and (max-width: 960px)': {
                fontSize: 16,
            }
        },
        h6:{
            fontSize: 21,
            fontWeight: 600,
            '@media screen and (max-width: 960px)': {
                fontSize: 16,
                fontFamily: 'Roboto Condensed',
            }
        },
        body1: {
            fontSize: 16,
        },
        body2: {
            fontFamily: [
                'Roboto',
                'Helvetica Neue',
                'sans-serif'
            ].join(','),
            fontSize: 16,

        },
        subtitle1: {
            fontFamily: [
                'Roboto Condensed',
                'Helvetica Neue',
                'sans-serif'
            ].join(','),
            fontSize: 25,

        },
        subtitle2: {
            fontFamily: [
                'Roboto',
                'Helvetica Neue',
                'sans-serif'
            ].join(','),
            fontSize: 18,

        },
        button: {
            textTransform: 'none',
        }

    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                color: '#232535',
                backgroundColor: '#fff'
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0px 2px 6px rgba(0,0,0,0.04)'
            },
            rounded: {
                borderRadius: 0,
            }
        },
        MuiButton: {
            root: {
                borderRadius: 0,
                fontWeight: 600,
            },
            contained: {
                backgroundColor: '#FFFFFF',
                border: '1px solid #9bd0c7',
                color: '#9bd0c7'
            }
        },
        MuiBadge: {
            dot: {
                height: '8px',
                minWidth: '8px'
            },
            colorPrimary: {
                backgroundColor: '#FFCA83'
            }
        },
    }
});
