import {Customer} from '@sascha_kirstein/prime-mobility'

const initState = {
    action: null,
    error: null,
    customerID: 'customerID',
    customer: new Customer(),
    data: {},
    ordered: [],
    keys: []
};

export const customerActions = {
    LOAD_CUSTOMER_SUCCESS: 'LOAD_CUSTOMER_SUCCESS',
    LOAD_CUSTOMER_ERROR: 'LOAD_CUSTOMER_ERROR',
    SET_CURRENT_CUSTOMER_SUCCESS: 'SET_CURRENT_CUSTOMER_SUCCESS',
    SET_CURRENT_CUSTOMER_ERROR: 'SET_CURRENT_CUSTOMER_ERROR',
    SET_CUSTOMER_ID_SUCCESS: 'SET_CUSTOMER_ID_SUCCESS'
};

const customerReducer = (state = initState, action) => {
    switch(action.type){
        case customerActions.LOAD_CUSTOMER_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                data: action.data,
                ordered: Object.values(action.data),
                keys: Object.keys(action.data),
                customer: action.data[state.customerID] || state.current,
            };
        case customerActions.SET_CURRENT_CUSTOMER_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                customer: action.customer,
            }
        case customerActions.SET_CUSTOMER_ID_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                customerID: action.customerID
            }
        case customerActions.LOAD_CUSTOMER_ERROR:
        case customerActions.SET_CURRENT_CUSTOMER_ERROR:
            return {
                ...state,
                action: action.type,
                error: action.err,
            };
        default:
            return state
    }
};

export default customerReducer;