import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from './config/fbConfig'
import createStore from "./store/createStore";

// Redux + Firebase
import {Provider} from 'react-redux'
import {ReactReduxFirebaseProvider} from 'react-redux-firebase'
import {createFirestoreInstance} from 'redux-firestore';

// import i18n
import './i18n';


// react-redux-firebase config
const rrfConfig = {
    userProfile: "user",
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
    enableClaims: true // add custom claims in state.firebase.profile.token.claims.
};


const store = createStore();

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
};

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
