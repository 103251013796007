import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { getFirebase } from 'react-redux-firebase'



// own
import rootReducer from './reducer/rootReducer';


const initialState = {};


const middlewares = [
    thunk.withExtraArgument(getFirebase)
];

export default () => {
    return createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middlewares),
    )
)};