import {Request} from '@sascha_kirstein/prime-mobility'

const initState = {
    action: null,
    error: null,
    current: new Request(),
    checkout: new Request(),
    data: {},
    ordered: [],
    keys: []
};

export const requestAction = {
    LOAD_REQUEST_SUCCESS: 'LOAD_REQUEST_SUCCESS',
    LOAD_REQUEST_ERROR: 'LOAD_REQUEST_ERROR',
    SET_CHECKOUT_REQUEST_SUCCESS: 'SET_CHECKOUT_REQUEST_SUCCESS',
    SET_CHECKOUT_REQUEST_ERROR: 'SET_CHECKOUT_REQUEST_ERROR',
    SET_CURRENT_REQUEST_SUCCESS: 'SET_CURRENT_REQUEST_SUCCESS',
    SET_CURRENT_REQUEST_ERROR: 'SET_CURRENT_REQUEST_ERROR',
    UPDATE_REQUEST_SUCCESS: 'UPDATE_REQUEST_SUCCESS',
    UPDATE_REQUEST_ERROR: 'UPDATE_REQUEST_ERROR',
    UPLOAD_REQUEST_FILE_SUCCESS: 'UPLOAD_REQUEST_FILE_SUCCESS,'
};

const userReducer = (state = initState, action) => {
    switch(action.type){
        case requestAction.LOAD_REQUEST_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                data: action.data,
                ordered: Object.values(action.data),
                keys: Object.keys(action.data)
            };
        case requestAction.SET_CURRENT_REQUEST_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                current: action.current,
            };
        case requestAction.SET_CHECKOUT_REQUEST_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                checkout: action.checkout,
            };
        case requestAction.UPDATE_REQUEST_SUCCESS:
        case requestAction.UPLOAD_REQUEST_FILE_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
            };
        case requestAction.UPDATE_REQUEST_ERROR:
        case requestAction.LOAD_REQUEST_ERROR:
        case requestAction.SET_CHECKOUT_REQUEST_ERROR:
        case requestAction.SET_CURRENT_REQUEST_ERROR:
            return {
                ...state,
                action: action.type,
                error: action.err,
            };
        default:
            return state
    }
};

export default userReducer;