import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const ENVIRONMENT = {
    DEV: 'DEV',
    LIVE: 'LIVE'
}
const fbConfigs = {
    [ENVIRONMENT.LIVE]: {
        apiKey: "AIzaSyAZzFSc2qCwQCmP2HCIBZgq6mkJUzYzmMc",
        authDomain: "prime-mobility-de.firebaseapp.com",
        projectId: "prime-mobility-de",
        storageBucket: "prime-mobility-de.appspot.com",
        messagingSenderId: "133248646002",
        appId: "1:133248646002:web:474ec5a3ab4d8952113beb",
        measurementId: "G-TM58PZVJLV"
    }
}

// Auto Environment SetUp based on hostname
const curEnvironment = ENVIRONMENT.LIVE
firebase.initializeApp(fbConfigs[curEnvironment]);
firebase.firestore();
firebase.storage();

export default firebase;

