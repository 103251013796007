import {Car} from '@sascha_kirstein/prime-mobility'

const initState = {
    action: null,
    error: null,
    carID: 'carID',
    car: new Car(),
    carData: {},
    carValues: [],
    carKeys: []
};

export const carActions = {
    LOAD_CAR_SUCCESS: 'LOAD_CAR_SUCCESS',
    LOAD_CAR_ERROR: 'LOAD_CAR_ERROR',
    SET_CURRENT_CAR_SUCCESS: 'SET_CURRENT_CAR_SUCCESS',
    SET_CURRENT_CAR_ERROR: 'SET_CURRENT_CAR_ERROR',
    SET_CAR_ID_SUCCESS: 'SET_CAR_ID_SUCCESS',
    ADD_CAR_SUCCESS: 'ADD_CAR_SUCCESS',
    ADD_CAR_ERROR: 'ADD_CAR_ERROR',
    UPDATE_CAR_SUCCESS: 'UPDATE_CAR_SUCCESS',
    UPDATE_CAR_ERROR: 'UPDATE_CAR_ERROR',
    UPLOAD_CAR_IMAGE_SUCCESS: 'UPLOAD_CAR_IMAGE_SUCCESS',
    UPLOAD_CAR_FILE_SUCCESS: 'UPLOAD_CAR_FILE_SUCCESS',
};

const carReducer = (state = initState, action) => {
    switch(action.type){
        case carActions.LOAD_CAR_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                carData: action.data,
                carValues: Object.values(action.data),
                carKeys: Object.keys(action.data),
                car: action.data[state.carID] || state.current,
            };
        case carActions.SET_CURRENT_CAR_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                car: action.car,
            }
        case carActions.SET_CAR_ID_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                carID: action.carID
            }
        case carActions.UPDATE_CAR_SUCCESS:
        case carActions.UPLOAD_CAR_IMAGE_SUCCESS:
        case carActions.ADD_CAR_SUCCESS:
        case carActions.UPLOAD_CAR_FILE_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
            }
        case carActions.ADD_CAR_ERROR:
        case carActions.LOAD_CAR_ERROR:
        case carActions.SET_CURRENT_CAR_ERROR:
        case carActions.UPDATE_CAR_ERROR:
            return {
                ...state,
                action: action.type,
                error: action.err,
            };
        default:
            return state
    }
};

export default carReducer;