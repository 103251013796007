import {Order} from '@sascha_kirstein/prime-mobility'

const initState = {
    action: null,
    error: null,
    current: new Order(),
    data: {},
    ordered: [],
    keys: []
};

export const orderAction = {
    LOAD_ORDER_SUCCESS: 'LOAD_ORDER_SUCCESS',
    LOAD_ORDER_ERROR: 'LOAD_ORDER_ERROR',
    SET_CURRENT_ORDER_SUCCESS: 'SET_CURRENT_ORDER_SUCCESS',
    SET_CURRENT_ORDER_ERROR: 'SET_CURRENT_ORDER_ERROR',
};

const orderReducer = (state = initState, action) => {
    switch(action.type){
        case orderAction.LOAD_ORDER_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                data: action.data,
                ordered: Object.values(action.data),
                keys: Object.keys(action.data)
            };
        case orderAction.SET_CURRENT_ORDER_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                current: action.current,
            };
        case orderAction.LOAD_ORDER_ERROR:
        case orderAction.SET_CURRENT_ORDER_ERROR:
            return {
                ...state,
                action: action.type,
                error: action.err,
            };
        default:
            return state
    }
};

export default orderReducer;