import {appAction} from "../reducer/appReducer";

export const setLogInStatus = (loggedIn) => {
    return (dispatch, getState, getFirebase) => {
        const userID = loggedIn ? getState().firebase?.auth?.uid : '';
        dispatch({type: appAction.SET_LOGIN_STATUS, loggedIn, userID});
    }
};

export const configName = (userID = '') => `config[${userID.substring(0, 6)}]`;
export const setConfig = (config = {}, fromLocalStorage = false) => {
    return (dispatch, getState, getFirebase) => {
        const userID = getState().app.userID;

        if (fromLocalStorage) {
            dispatch({type: appAction.SET_CONFIG_SUCCESS, config: config, configLoaded: true});
        } else {
            addObjectToLocalStorage(configName(userID), config)
            dispatch({type: appAction.SET_CONFIG_SUCCESS, config: config});
        }

    }
};

export const COOKIE_NAME = 'cookie'
export const setCookie = (cookie = {}, fromLocalStorage = false) => {
    return (dispatch, getState, getFirebase) => {
        const _cookie = {
            marketing: Boolean(cookie.marketing),
            personalization: Boolean(cookie.personalization),
            analytics: Boolean(cookie.analytics),
        }
        if (fromLocalStorage) {
            dispatch({type: appAction.SET_COOKIE_SUCCESS, cookie: _cookie, cookieLoaded: true});
        } else {
            addObjectToLocalStorage(COOKIE_NAME, cookie)
            dispatch({type: appAction.SET_COOKIE_SUCCESS, cookie: _cookie});
        }

    }
};

const addObjectToLocalStorage = (key = '', obj) => {
    if (key && obj) {
        try {
            localStorage.setItem(key, JSON.stringify(obj))
        } catch (e) {
            console.log('Could not persist app config')
        }
    }
}

export const showTopBar = (showTopBar = true) => {
    return (dispatch, getState, getFirebase) => {
        dispatch({type: appAction.SET_SHOW_TOP_BAR, showTopBar: showTopBar})
    }
}

export const setFAQ = (faq = {}) => {
    return (dispatch, getState, getFirebase) => {
        dispatch({type: appAction.SET_FAQ, faq})
    }
}
