const initState = {
    action: null,
    error: null,
    config: {},
    configLoaded: false,
    cookie: {
        marketing: false,
        personalization: false,
        analytics: false
    },
    cookieLoaded: false,
    authLoaded: false,
    loggedIn: false,
    userID: '',
    showTopBar: true,
    faq: {},
};

export const appAction = {
    SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',
    SET_CONFIG_SUCCESS: 'SET_CONFIG_SUCCESS',
    SET_COOKIE_SUCCESS: 'SET_COOKIE_SUCCESS',
    SET_SHOW_TOP_BAR: 'SET_SHOW_TOP_BAR',
    SET_FAQ: 'SET_FAQ'
};

const appReducer = (state = initState, action) => {
    switch(action.type){
        case appAction.SET_LOGIN_STATUS:{
            return {
                ...state,
                action: action.type,
                error: null,
                loggedIn: action.loggedIn,
                userID: action.userID,
                authLoaded: true,
            };
        }
        case appAction.SET_CONFIG_SUCCESS:{
            return {
                ...state,
                action: action.type,
                error: null,
                config: action.config,
                configLoaded: action.configLoaded ? true : state.configLoaded,
            };
        }
        case appAction.SET_COOKIE_SUCCESS:{
            return {
                ...state,
                action: action.type,
                error: null,
                cookie: {
                    marketing: Boolean(action.cookie.marketing),
                    personalization: Boolean(action.cookie.personalization),
                    analytics: Boolean(action.cookie.analytics),
                },
                cookieLoaded: action.cookieLoaded ? true : state.cookieLoaded,
            };
        }
        case appAction.SET_SHOW_TOP_BAR:{
            return {
                ...state,
                action: action.type,
                error: null,
                showTopBar: action.showTopBar
            };
        }
        case appAction.SET_FAQ:{
            return {
                ...state,
                action: action.type,
                error: null,
                faq: action.faq
            };
        }
        default:
            return state
    }
};

export default appReducer;