import React from 'react';

import {makeStyles} from "@material-ui/core";


export default function Loading() {

    const classes = useStyles();

    return (
        <div class="full-page-loader">
            <img
                width='200'
                src={'/icon/prime-mobility-white.svg'}
                alt='PRIME MOBILITY LOGO'
            />
        </div>
    )
}


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: '#9bd0c7',
    },
}));