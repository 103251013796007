import React, {lazy, Suspense, useState} from 'react';
import {primeMobilityTheme} from './config/theme.js';
import {App as PM} from '@sascha_kirstein/prime-mobility';

import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';


import Loading from "./page/Loading";
import {useDispatch, useSelector} from "react-redux";
import {setLogInStatus} from "./store/action/appAction";

const Landing = lazy(() => import("./page/landing/Landing"));
const Imprint = lazy(() => import("./page/Imprint"));
const Terms = lazy(() => import("./page/Terms"));
const Privacy = lazy(() => import("./page/Privacy"));
const SignUp = lazy(() => import("./page/auth/SignUp"));
const CarModels = lazy(() => import("./page/carModel/CarModels"));
const CarModel = lazy(() => import("./page/carModel/CarModel"));
const CheckOut = lazy(() => import("./page/checkout/CheckOut"));
const CheckoutSuccess = lazy(() => import("./page/checkout/CheckoutSuccess"));
const Profile = lazy(() => import("./page/profile/Profile"));
const Orders = lazy(() => import("./page/order/Orders"));
const Order = lazy(() => import("./page/order/Order"));
const MainArea = lazy(() => import("./component/layout/MainArea"));
const FSConnect = lazy(() => import("./store/FSConnect"));
const APIConnect = lazy(() => import("./APIConnect"));
const DataAcquisition = lazy(() => import("./DataAcquisition"));
const FAQ = lazy(() => import("./page/FAQ"));
const PageTitle = lazy(() => import('./PageTitle'));
const SuperUser = lazy(() => import('./page/superUser/SuperUser'));
const SuperUserCarModels = lazy(() => import('./page/superUser/SuperUserCarModels'));
const SuperUserCars = lazy(() => import('./page/superUser/SuperUserCars'));
const SuperUserCar = lazy(() => import('./page/superUser/SuperUserCar'));
const SuperUserOrders = lazy(() => import('./page/superUser/SuperUserRequests'));
const SuperUserOrder = lazy(() => import('./page/superUser/SuperUserRequest'));
const SuperUserRentals = lazy(() => import('./page/superUser/SuperUserOrders'));
const SuperUserRental = lazy(() => import('./page/superUser/SuperUserOrder'));
const SuperUserCustomers = lazy(() => import('./page/superUser/SuperUserCustomers'));
const SuperUserCustomer = lazy(() => import('./page/superUser/SuperUserCustomer'));
const SuperUserPayments = lazy(() => import('./page/superUser/SuperUserPayments'));
const SuperUserPayment = lazy(() => import('./page/superUser/SuperUserPayment'));
const SuperUserInvoices = lazy(() => import('./page/superUser/SuperUserInvoices'));
const SuperUserInvoice = lazy(() => import('./page/superUser/SuperUserInvoice'));
const Car = lazy(() => import('./page/car/Car'));

const APP_STATE = {
    BOOT: 'BOOT',
    LOGGED_OUT: 'LOGGED_OUT',
    LOGGED_IN: 'LOGGED_IN'
}

function App() {
    const dispatch = useDispatch();
    const fbProfile = useSelector(state => state.firebase.profile);
    const signedIn = (fbProfile?.token?.signInProvider === 'password');
    // While fbAuth and fbProfile is not loaded, app state is boot
    const [appState, setAppState] = useState(APP_STATE.BOOT);

    if (fbProfile.isLoaded) {
        // if signInProvider is not password, the user is not logged in
        if (fbProfile?.token?.signInProvider !== 'password' && appState !== APP_STATE.LOGGED_OUT) {
            setAppState(APP_STATE.LOGGED_OUT);
            dispatch(setLogInStatus(false))
        }
        // user is logged in
        if (fbProfile?.token?.signInProvider === 'password' && [APP_STATE.BOOT, APP_STATE.LOGGED_OUT].includes(appState)) {
            setAppState(APP_STATE.LOGGED_IN);
            dispatch(setLogInStatus(true))
        }
    }

    if (appState === APP_STATE.BOOT) {
        return (
            <MuiThemeProvider theme={primeMobilityTheme}>
                <Loading/>
            </MuiThemeProvider>
        )
    }


    return (
        <BrowserRouter>
            <MuiThemeProvider theme={primeMobilityTheme}>
                <Suspense fallback={<Loading/>}>
                    <MainArea>
                        <Switch>
                            <Route exact path='/' component={Landing}/>

                            <Route path={PM.ROUTES.IMPRINT} component={Imprint}/>
                            <Route path={PM.ROUTES.TERMS_AND_CONDITIONS} component={Terms}/>
                            <Route path={PM.ROUTES.PRIVACY} component={Privacy}/>
                            <Route path={PM.ROUTES.FAQ} component={FAQ}/>

                            <Route path={PM.ROUTES.CAR_MODEL + ':id'} component={CarModel}/>
                            <Route path={PM.ROUTES.CAR_MODELS} component={CarModels}/>

                            <Route path={PM.ROUTES.CAR + ':id'} component={Car}/>

                            <Route path={PM.ROUTES.CHECKOUT} component={CheckOut}/>
                            <Route path={PM.ROUTES.SUCCESS} component={CheckoutSuccess}/>

                            <Route path={PM.ROUTES.ORDER + ':id'} component={Order}/>
                            <Route path={PM.ROUTES.ORDERS} component={Orders}/>

                            <ProtectedRoute path={PM.ROUTES.PROFILE} component={Profile}/>

                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_CAR_MODELS} component={SuperUserCarModels}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_CAR + ':id'} component={SuperUserCar}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_CARS} component={SuperUserCars}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_REQUEST + ':id'} component={SuperUserOrder}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_REQUESTS} component={SuperUserOrders}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_ORDER + ':id'} component={SuperUserRental}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_ORDERS} component={SuperUserRentals}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_CUSTOMER + ':id'} component={SuperUserCustomer}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_CUSTOMERS} component={SuperUserCustomers}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_INVOICE + ':id'} component={SuperUserInvoice}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_INVOICES} component={SuperUserInvoices}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_PAYMENT + ':id'} component={SuperUserPayment}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER_PAYMENTS} component={SuperUserPayments}/>
                            <SuperUserRoute path={PM.ROUTES.SUPERUSER} component={SuperUser}/>

                            <Route path={PM.ROUTES.SIGNUP} component={SignUp}/>

                            <Route>
                                <Redirect to='/'/>
                            </Route>
                        </Switch>
                    </MainArea>
                    <APIConnect/>
                    <PageTitle/>
                    {signedIn && <FSConnect/>}
                    <DataAcquisition/>
                </Suspense>
            </MuiThemeProvider>
        </BrowserRouter>
    );
}

function ProtectedRoute(props) {
    const fbProfile = useSelector(state => state.firebase.profile);
    const signedIn = (fbProfile?.token?.signInProvider === 'password');
    return signedIn ? <Route path={props.path} component={props.component}/> : <Redirect to='/'/>
}

function SuperUserRoute(props) {
    const fbProfile = useSelector(state => state.firebase.profile);
    const superUser = (fbProfile?.token?.claims?.superUser);
    return superUser ? <Route path={props.path} component={props.component}/> : <Redirect to='/'/>
}


export default App;
