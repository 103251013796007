import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

// own reducers
import authReducer from './authReducer';
import appReducer from "./appReducer";
import userReducer from "./userReducer";
import carModelReducer from "./carModelReducer";
import requestReducer from "./requestReducer";
import customerReducer from "./customerReducer";
import carReducer from "./carReducer";
import superUserReducer from "./superUserReducer";
import orderReducer from "./orderReducer";


const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    carModel: carModelReducer,
    customer: customerReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    user: userReducer,
    request: requestReducer,
    order: orderReducer,
    car: carReducer,
    superUser: superUserReducer,
});

export default rootReducer;