import {User} from '@sascha_kirstein/prime-mobility'

const initState = {
    action: null,
    error: null,
    user: new User(),
    superUser: false,
    data: {},
    ordered: [],
    keys: []
};

export const userActions = {
    LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
    LOAD_USER_ERROR: 'LOAD_USER_ERROR',
    SET_USER_SUCCESS: 'SET_CURRENT_USER_SUCCESS',
    SET_USER_ERROR: 'SET_CURRENT_USER_ERROR',
};

const userReducer = (state = initState, action) => {
    switch(action.type){
        case userActions.LOAD_USER_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                data: action.data,
                ordered: Object.values(action.data),
                keys: Object.keys(action.data)
            };
        case userActions.SET_USER_SUCCESS:
            return {
                ...state,
                action: action.type,
                error: null,
                user: action.user,
                superUser: action.superUser,
            }
        case userActions.LOAD_USER_ERROR:
        case userActions.SET_USER_ERROR:
            return {
                ...state,
                action: action.type,
                error: action.err,
            };
        default:
            return state
    }
};

export default userReducer;